// import moment from "moment";
// moment.locale("es");

export const formatNumber = (value) => {
  let price = null;

  if (value?.split(".")) {
    value = value?.split(".")?.join("")?.replace(/\D/g, "");

    if (isNaN(value)) value = null;
  } else {
    if (isNaN(value)) value = null;
  }

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    minimumFractionDigits: 0,
  });

  price = formatterPeso.format(value);

  return price;
}

export const formatPrice = (value) => {
  let price = null;

  if (value?.split(".")) {
    value = value?.split(".")?.join("")?.replace(/\D/g, "");

    if (isNaN(value)) value = null;
  } else {
    if (isNaN(value)) value = null;
  }

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  price = formatterPeso.format(value);

  return price;
}

export const formatDateTime = (value) => {
  return value.split("T").join(" ").split(".")[0];
}